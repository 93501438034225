import CapacitySwiperItem from "components/analytics/CapacitySwiperItem";
import ConnectingDots from "components/analytics/ConnectingDots";
import RackStack from "components/analytics/RackStack";
import Fade from "components/animations/Fade";
import EmptyContent from "components/base/EmptyContent";
import { useBackground } from "components/base/layout/Background";
import Swiper from "components/base/Swiper/Swiper";
import Heading1 from "components/base/text/Heading1";
import Select from "components/form/Select";
import JourneyItem from "components/Journey/JourneyItem";
import useAnalyticsLab from "hooks/analytics/useAnalyticsLab";
import useLabs from "hooks/labs/useLabs";
import useProfile from "hooks/user/useProfile";
import useWidth from "hooks/utils/useWidth";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { TCapacityHistoryItem } from "types/analytics";

export default function AnalyticsPage() {
  useBackground("rgb(102,77,243)", true);
  const navigate = useNavigate();
  const { gameid } = useParams<{ gameid: string }>();
  const { profile, isProfileLoading } = useProfile();
  const { analyticsLab } = useAnalyticsLab(gameid);
  const { labs, isLabsLoading } = useLabs();
  const isMobile = useWidth() < 840;

  const [searchParams] = useSearchParams();
  const cycleId = searchParams.get("cycleid");

  useEffect(() => {
    if (!gameid && labs?.length) {
      navigate(window.location.pathname + "/" + labs[0].id, { replace: true });
    }
  }, [gameid, labs, navigate]);

  useEffect(() => navigate("/"), [navigate]);

  if (isLabsLoading || isProfileLoading || !profile) return null;

  if (!labs?.length) return <EmptyContent />;

  const { linearChart, coach, lab } = analyticsLab || {};
  const { enabled: chartEnabled } = linearChart || {};

  const hasData =
    analyticsLab?.connectingDots ||
    analyticsLab?.rackStackValues ||
    (chartEnabled &&
      linearChart?.capacities
        .map(i => parseLinearChartData(linearChart.data, i.score))
        .some(i => i.length > 2 || i[i.length - 1].insight));

  return (
    <Fade delay={100}>
      <LabSelect>
        <Select
          value={gameid || ""}
          onChange={(e: any) => navigate("/analytics/" + e.target.value)}
        >
          {labs?.sort().map((lab: any) => (
            <option key={lab.id} value={lab.id}>
              {lab.name}
            </option>
          ))}
        </Select>
      </LabSelect>
      {analyticsLab && (
        <Page>
          <Section>
            <PageHeading>{lab?.prompt?.name}</PageHeading>

            {/* <JourneyItem
              headline={lab?.prompt?.name}
              message={<p style={{ fontWeight: 700 }}>{lab?.prompt?.message}</p>}
              sender={coach?.name}
              date={lab?.startAt}
            /> */}

            <JourneyItem
              headline={`Insights from ${coach?.name}`}
              message={lab?.prompt?.defaultResponse}
              sender={coach?.name}
              date={lab?.startAt}
            />
          </Section>

          {hasData && <PageHeading>Insights based on your response</PageHeading>}

          {hasData && (
            <Swiper showNavigation={!isMobile}>
              {linearChart?.capacities.map(capacity => {
                const { label, score } = capacity;
                const { data, enabled: chartEnabled } = linearChart;
                const parsedData = parseLinearChartData(data, score);

                const selectedCycle = parsedData.findIndex(i => i.cycleId === cycleId);
                const initialSelected =
                  selectedCycle === -1 ? parsedData.length - 1 : selectedCycle;

                if (parsedData.length < 3 && !parsedData[initialSelected]?.insight)
                  return cycleId;

                return (
                  <CapacitySwiperItem
                    key={Math.random()}
                    data={parsedData}
                    label={label}
                    chartEnabled={chartEnabled}
                    initialSelected={initialSelected}
                  />
                );
              })}
            </Swiper>
          )}

          <Section>
            <RackAndStackContainer>
              {analyticsLab?.connectingDots && (
                <ConnectingDots data={analyticsLab.connectingDots} />
              )}
              {analyticsLab.rackStackValues && (
                <RackStack data={analyticsLab.rackStackValues} />
              )}
            </RackAndStackContainer>

            {/* {hasAnalytics && (
              <Card>
                <WaitingContainer>
                  <WaitingFigure width={105} />
                  <Heading2>This is taking longer than expected</Heading2>
                  <p>Check back later</p>
                </WaitingContainer>
              </Card>
            )} */}

            {/* <Prompt>
              {userResponse?.message?.text && (
                <>
                  <JourneyItem
                    headline={userResponse?.headline}
                    message={userResponse?.message?.text}
                    sender={profile.fullName}
                    date={userResponse?.message?.date}
                  />
                </>
              )}
            </Prompt> */}
          </Section>
        </Page>
      )}
    </Fade>
  );
}

// const WaitingContainer = styled.div`
//   align-items: center;
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
//   justify-content: center;
//   padding: 4rem 0;
//   text-align: center;
// `;

const LabSelect = styled.div`
  left: 1rem;
  position: absolute;
  top: 1.25rem;
  z-index: 1;
  & > div {
    max-width: calc(100vw - 7rem);
    width: 300px;
  }
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 6rem 0rem 4rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 1rem;
  width: 720px;
`;

const PageHeading = styled(Heading1)`
  color: white;
  padding: 0 2rem;
  text-align: center;
`;

const RackAndStackContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (min-width: 720px) {
    flex-direction: row;
    justify-content: center;
    & > div {
      width: 50%;
    }
  }
`;

// const Prompt = styled.div`
//   margin: 0 auto;
// `;

function parseLinearChartData(data: any, label: string): TCapacityHistoryItem[] {
  return data?.map((item: any, i: number) => {
    return {
      date: item.date,
      cycleId: item.cycleId,
      value: item.scores[label].score,
      max: item.scores[label].max,
      min: item.scores[label].min,
      avg: item.scores[label].avg,
      insight: item.scores[label].insight,
    };
  });
}

// function parseWords(words: Record<string, number>): TWordsItem[] {
//   return Object.keys(words).map(key => ({
//     text: key,
//     value: words?.[key] || 0,
//   }));
// }
