// import { IGetUserAnalyticsResV2 } from "@dailyhuman/web-core/dist/api/game/res";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TAnalytics } from "types/analytics";
import { apiRoute, commonHeaders } from "utils/api";

export type TAnalyticsGroupUser = {
  user: {
    id: string;
    name: string;
  };
  analytics: TAnalytics;
};

export default function useAnalyticsGroupUser(gameId?: string, userId?: string) {
  const { data: response, isFetching: isAnalyticsGroupUserLoading } = useQuery({
    queryKey: ["useAnalyticsGroupUser", gameId, userId],
    queryFn: () =>
      axios.get<any>(
        `${apiRoute}/recognition/analytics/game/${gameId}/user/${userId}`,
        {
          ...commonHeaders(),
        },
      ),
    enabled: !!gameId && !!userId, // Ensures the query only runs when both gameId and userId are not null or undefined
  });

  const analyticsGroupUser = response?.data?.result;

  return { analyticsGroupUser, isAnalyticsGroupUserLoading };
}
