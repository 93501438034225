import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { SnackBarContext } from "components/base/Alert/Alert";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { apiRoute } from "utils/api";

import { BrowserStorageItem, localStorageService } from "../utils/StorageHelper";

type DeveloperLoginReq = {
  email: string;
  password: string;
};

function useDeveloperLogin() {
  const context = useContext(SnackBarContext);
  const navigate = useNavigate();

  const {
    mutate: developerLogin,
    isPending: isDeveloperLoging, // Changed from isLoading to isPending
  } = useMutation({
    mutationFn: (data: DeveloperLoginReq) =>
      axios.post(`${apiRoute}/auth/sign-in`, data),
    onSuccess: data => {
      localStorageService.set(BrowserStorageItem.AUTH_TOKEN, data.data.accessToken);
      navigate("/");
    },
    onError: () => {
      context?.setSnackBar({
        status: "failure",
        text: "Incorrect email or password :(",
      });
    },
  });

  return { developerLogin, isDeveloperLoging };
}

export { useDeveloperLogin };
