import { LaunchGameType } from "@dailyhuman/web-core/dist/api/onboarding/types";
import Quote from "components/base/Quote";
import Epoch from "components/base/text/Epoch";
import styled from "styled-components";

interface MessageProps {
  isUserMessage: boolean;
  message: string;
}

export const Message = ({ isUserMessage, message }: MessageProps) => {
  return isUserMessage ? (
    <UserMessage message={message} />
  ) : (
    <ServerMessage message={message} />
  );
};

export const UserMessage = ({ message }: { message: string }) => {
  return <UserMessageWrapper>{message}</UserMessageWrapper>;
};

export const ServerMessage = ({ message }: { message: string }) => {
  const quoteRegex = /Here’s your answer so far:.*?[.!?]*/g;
  const match = message.match(quoteRegex);
  if (match) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, end] = message.split("Here’s your answer so far:");
    return (
      <ServerMessageWrapper>
        {"Here’s your answer so far:"}
        <ServerQuote>{end}</ServerQuote>
      </ServerMessageWrapper>
    );
  }
  return <ServerMessageWrapper>{message}</ServerMessageWrapper>;
};

export const HelloMessage = ({
  partnerName,
  end,
  type,
}: {
  partnerName: string;
  end: string;
  type?: LaunchGameType;
}) => {
  return (
    <ServerMessageWrapper>
      {type !== LaunchGameType.SALES_TEST_GAME_ONLY && (
        <b>
          Respond by <Epoch date={end} />
        </b>
      )}
      {type === LaunchGameType.COMMUNITY_GAME && (
        <>
          <br />
          {`Your reflection will be shared with ${partnerName}.`}
        </>
      )}
      <br />
      <br />
    </ServerMessageWrapper>
  );
};

const ServerQuote = styled(Quote)`
  margin: 0 1rem 0 auto;
  max-width: 80%;
  @media (min-width: 768px) {
    max-width: 70%;
  }
`;

const UserMessageWrapper = styled(ServerQuote)`
  padding: 1rem !important;
  > svg {
    display: none;
  }
`;

export const ServerMessageWrapper = styled.div`
  color: #676464;
  max-width: 800px;
  padding: 1rem 1rem 0 1rem;
  white-space: pre-line;
  & > div {
    margin: 1rem 0 -2rem;
    position: relative;
  }
  b {
    font-weight: 600;
  }
  & > div > svg {
    left: -10px;
    position: absolute;
    top: -10px;
  }
`;
