import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { apiRoute, commonHeaders } from "utils/api";

type TAnalyticsGroupPrompts = {
  data: {
    id: string;
    name: string;
  }[];
};

export default function useAnalyticsGroupPrompts(groupId: string) {
  const { data: response, isFetching: isAnalyticsGroupUsersLoading } = useQuery({
    queryKey: ["useAnalyticsGroupUsers", groupId],
    queryFn: () =>
      axios.get<TAnalyticsGroupPrompts>(
        `${apiRoute?.slice(0, -3)}/v2/prompt-set/by-coach`,
        {
          ...commonHeaders(),
          params: {
            hasAnalytics: true,
            groupId,
          },
        },
      ),
    enabled: !!groupId, // Ensures the query only runs when groupId is not null or undefined
  });

  return { isAnalyticsGroupUsersLoading, analyticsGroupPrompts: response?.data?.data };
}
