import { IListJourneyV3Res } from "@dailyhuman/web-core/dist/api/game/res";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { apiRoute, commonHeaders } from "../utils/api";

type UserJourneyProps = {
  userId?: string;
  page?: number;
};

function useGetUserJourney({ userId, page = 1 }: UserJourneyProps) {
  const params: { limit: number; page?: number; partnerId?: string } = {
    limit: 20,
    page: page,
  };

  if (userId) {
    params.partnerId = userId;
  }

  const { data, isFetching: isJourneyLoading } = useQuery({
    queryKey: ["getUserJourney", userId, page],
    queryFn: () =>
      axios.get<IListJourneyV3Res>(`${apiRoute?.slice(0, -3)}/v3/recognition/`, {
        params,
        ...commonHeaders(),
      }),
  });

  const journey = data?.data;

  return { journey, isJourneyLoading };
}

export { useGetUserJourney };
