import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { apiRoute, commonHeaders } from "../utils/api";

export type CoachGroups = {
  id: string;
  name: string;
  children: CoachGroups;
}[];

function useGetCoachGroups() {
  const { data, isFetching: isCoachGroupsLoading } = useQuery({
    queryKey: ["useAnalytics"],
    queryFn: () =>
      axios.get<CoachGroups>(`${apiRoute}/group/by-coach`, {
        ...commonHeaders(),
        params: {
          hasAnalytics: true,
        },
      }),
  });

  return { coachGroups: data?.data, isCoachGroupsLoading };
}

export default useGetCoachGroups;
