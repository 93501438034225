import { IRegisterAndJoinOnboardingPoolReq } from "@dailyhuman/web-core/dist/api/onboarding/req";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { SnackBarContext } from "components/base/Alert/Alert";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { apiRoute, commonHeaders } from "utils/api";

import { BrowserStorageItem, localStorageService } from "../utils/StorageHelper";

interface UserJoinReq {
  poolSlug?: string;
}

interface UserRegisterRes {
  id: string;
  accessToken: string;
}

function useRegisterUserToOnboardingPool() {
  const context = useContext(SnackBarContext);
  //const navigate = useNavigate();
  const { mutate: sendRegisterRequest, isPending: isRegistrationRequestLoading } =
    useMutation({
      mutationFn: (data: Partial<IRegisterAndJoinOnboardingPoolReq>) =>
        axios.post<
          Partial<IRegisterAndJoinOnboardingPoolReq>,
          AxiosResponse<UserRegisterRes>
        >(`${apiRoute}/user/sign-up`, data, commonHeaders()),
      onSuccess: data => {
        localStorageService.set(BrowserStorageItem.AUTH_TOKEN, data.data.accessToken);
        // navigate("/");
      },
      onError: (error: AxiosError) => {
        context?.setSnackBar({
          status: "failure",
          text: error.message,
        });
      },
    });

  return {
    sendRegisterRequest,
    isRegistrationRequestLoading,
  };
}

function useJoinUserToOnboardingPool() {
  const context = useContext(SnackBarContext);
  const navigate = useNavigate();
  const navigateToLabs = () => setTimeout(() => navigate("/labs"), 3000);
  const { mutate: sendJoinRequest, isPending: isJoinRequestLoading } = useMutation({
    mutationFn: (data: UserJoinReq) =>
      axios.post<IRegisterAndJoinOnboardingPoolReq, AxiosResponse<UserRegisterRes>>(
        `${apiRoute}/onboarding/pool/join`,
        data,
        commonHeaders(),
      ),
    onSuccess: navigateToLabs,
    onError: (error: AxiosError) => {
      // check if 409 error
      if (error.response?.status === 409) {
        navigateToLabs();
        return;
      }
      context?.setSnackBar({
        status: "failure",
        text: error.message,
      });
    },
  });

  return {
    sendJoinRequest,
    isJoinRequestLoading,
  };
}

export { useJoinUserToOnboardingPool, useRegisterUserToOnboardingPool };
