import { IListJourneyV3ResItem } from "@dailyhuman/web-core/dist/api/game/res";
import Accordion from "components/animations/Accordion";
import Fade from "components/animations/Fade";
import Switch from "components/animations/Switch";
import { useBackground } from "components/base/layout/Background";
import { ReactComponent as Labs } from "components/base/Menu/icons/Labs.svg";
import Epoch from "components/base/text/Epoch";
import Heading1 from "components/base/text/Heading1";
import Heading2 from "components/base/text/Heading2";
import Small from "components/base/text/Small";
import Button from "components/form/Button";
import dayjs from "dayjs";
import { useGetUserGames } from "hooks/useGetUserGames";
//import useProfile from "hooks/user/useProfile";
//import useWidth from "hooks/utils/useWidth";
import { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import Media from "../base/Media";
import { ReactComponent as BridgeSmall } from "./bridge-small.svg";
import { ReactComponent as Contact } from "./Contact.svg";
import IntersectionObserver from "./IntersectionObserver";

export type JourneyListProps = {
  journey?: IListJourneyV3ResItem[];
  isLoading?: boolean;
  onLoadMore?: () => void;
};

export default function JourneyList({
  journey,
  isLoading,
  onLoadMore,
}: JourneyListProps) {
  const [intersecting, setIntersecting] = useState(0);
  //const { profile } = useProfile();
  const { games } = useGetUserGames();
  //const isMobile = useWidth() < 840;

  const handleIntersect = (index: number) => {
    intersecting !== index && setIntersecting(index);
  };

  useBackground("rgb(102,77,243)", true);

  const lastOpenCycle = games?.data?.[0];

  return (
    <JourneyListContainer className="JourneyList">
      <Helmet>
        <meta name="theme-color" content={"rgb(102,77,243)"} />
      </Helmet>
      <ContentContainer>
        {!!lastOpenCycle && !!journey?.length && (
          <HeaderSection>
            <Heading2>{lastOpenCycle.game.name}</Heading2>
            <a href="/labs">
              <Button icon={Labs}>Answer</Button>
            </a>
            <ItemFooter>
              Expires <Epoch date={lastOpenCycle.forceCompleteAt} />
            </ItemFooter>
          </HeaderSection>
        )}
        {journey?.map((cycle, l) => (
          <IntersectionObserver onIntersection={() => {}} key={cycle.game.id}>
            <Fade delay={100}>
              <ItemContainer>
                <ItemCard>
                  <ItemCardImage src={cycle.card?.image} alt={cycle.prompt.name} />
                  <ItemCardContent>
                    <div>
                      <Heading2>{cycle.prompt.name}</Heading2>
                      <Small>{dayjs(cycle.cycle.startAt).format("MMM DD, YYYY")}</Small>
                    </div>
                    <div>
                      <Heading1>{cycle.card?.name}</Heading1>
                      MINDSET CARD
                    </div>
                  </ItemCardContent>
                </ItemCard>
                <ItemSection>
                  {cycle.userData?.selfTalk && (
                    <>
                      <Expander title="Your Self-Talk" initial={true}>
                        <p>
                          <HighlightWord match={cycle.card?.name}>
                            {cycle.userData?.selfTalk}
                          </HighlightWord>
                        </p>
                      </Expander>
                      <Separator />
                    </>
                  )}
                  {cycle.userData?.reframedStory && (
                    <>
                      <Expander title={cycle.user.name}>
                        <p>
                          <HighlightWord match={cycle.card?.name}>
                            {cycle.userData?.reframedStory}
                          </HighlightWord>
                        </p>
                      </Expander>
                      <Separator />
                    </>
                  )}

                  {cycle.coachData && (
                    <>
                      <Expander title={cycle.coachData?.name || "n/a"}>
                        <>
                          {cycle.coachData?.image && (
                            <>
                              <Media uri={cycle.coachData?.image} />
                              <br />
                            </>
                          )}
                          <p>
                            {" "}
                            <HighlightWord match={cycle.card?.name}>
                              {cycle.coachData?.reflection}
                            </HighlightWord>
                          </p>
                        </>
                      </Expander>
                      <Separator />
                    </>
                  )}
                  {cycle.partnerData && (
                    <>
                      <Expander title={cycle.partner.name}>
                        <p>
                          <HighlightWord match={cycle.card?.name}>
                            {cycle.partnerData?.reframedStory || ""}
                          </HighlightWord>
                        </p>
                      </Expander>
                      <Separator />
                    </>
                  )}
                </ItemSection>
              </ItemContainer>
            </Fade>
          </IntersectionObserver>
        ))}
        {onLoadMore && (
          <IntersectionObserver onIntersection={onLoadMore}>
            <LoadMoreButton onClick={onLoadMore}>Load more</LoadMoreButton>
          </IntersectionObserver>
        )}
        {!onLoadMore && !isLoading && Array.isArray(journey) && (
          <IntersectionObserver onIntersection={() => handleIntersect(journey.length)}>
            <Fade delay={100}>
              {journey.length === 0 && (
                <HeaderSection>
                  <Heading2>Answer the prompt</Heading2>
                  <a href="/labs">
                    <Button icon={Labs}>Answer the prompt</Button>
                  </a>
                </HeaderSection>
              )}
              <HeaderSection>
                <Heading2>Have you saved Dailyhuman's contact card?</Heading2>
                <a href="https://dh.live/save-contact">
                  <Button icon={Contact}>Save contact</Button>
                </a>
              </HeaderSection>
            </Fade>
          </IntersectionObserver>
        )}
      </ContentContainer>
    </JourneyListContainer>
  );
}

type ExpanderProps = {
  title: string;
  children: React.ReactNode;
  initial?: boolean;
  expanded?: boolean;
};

// expander component start
function Expander({ title, children, initial }: ExpanderProps) {
  const [isExpanded, setIsExpanded] = useState(initial);
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
    // const parent = ref.current?.parentElement;
    // if (parent && !isExpanded) {
    //   const siblings = parent.getElementsByClassName("Expander");
    //   Array.from(siblings).forEach(sibling => {
    //     if (sibling !== ref.current) {
    //       const title = sibling.getElementsByClassName("active")[0] as HTMLElement;
    //       title?.click();
    //     }
    //   });
    //   // scroll to title
    //   setTimeout(
    //     () => ref.current?.scrollIntoView({ behavior: "smooth", block: "center" }),
    //     700,
    //   );
    // }
  };

  return (
    <div ref={ref} className="Expander">
      <ItemTitle onClick={handleClick} className={isExpanded ? "active" : ""}>
        <svg
          width="20"
          height="18"
          viewBox="-2 0 20 10"
          fill="none"
          style={{
            transform: isExpanded ? "rotate(-180deg)" : "",
            transition: "all 0.6s",
          }}
        >
          <path
            d="M15.778 1.02677L8.38898 8.38898L1 1"
            stroke="#664DF3"
            stroke-width="2"
          />
        </svg>{" "}
        {title}
      </ItemTitle>
      <Accordion>
        <Switch renderKey={JSON.stringify(isExpanded)}>
          {isExpanded && <ExpanderContent>{children}</ExpanderContent>}
        </Switch>
      </Accordion>
    </div>
  );
}

const ExpanderContent = styled.div`
  padding-top: 0.5rem;
`;

const Separator = styled.div`
  background: #c7bef8;
  height: 1px;
  margin: 1rem 0;
`;

const Section = styled.div`
  background: #f9f7f4;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  max-width: calc(100% - 2rem);
  padding: 1rem;
  width: 100%;
`;

const HeaderSection = styled(Section)`
  gap: 1rem;
  margin: 0 auto;
  margin-bottom: 2rem;
  width: 420px;
`;

const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: calc(100% - 1rem);
  width: 760px;
`;

const ItemSection = styled(Section)`
  margin-top: -12rem;
  padding-top: 12rem;
`;

const ItemTitle = styled.h2`
  color: #664df3;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 700;
`;

const ItemCard = styled.div`
  margin: 0 auto 1.5rem;
  position: relative;
`;

const ItemCardImage = styled.img`
  border-radius: 1rem;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.2);
  display: block;
  height: 513px;
  margin: 0 auto;
  max-width: 100%;
  object-fit: cover;
  width: 760px;
`;

const ItemCardContent = styled.div`
  bottom: 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  line-height: 1;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0;
`;

const ItemFooter = styled.div`
  color: #ab997e;
`;

const JourneyListContainer = styled.div`
  min-height: calc(100dvh - 60px);
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 6rem 0rem 2rem 0rem;
`;

export const StyledBridgeSmall = styled(BridgeSmall)`
  display: block;
  height: 36px;
  margin: -1px auto;
  max-width: calc(100% - 100px);
  width: 200px;
`;
const LoadMoreButton = styled.button`
  background: #f9f7f4;
  border: none;
  border-radius: 50%;
  color: #676464;
  cursor: pointer;
  display: block;
  font-weight: 800;
  height: 6rem;
  margin: 54px auto 0 auto;
  outline: none;
  text-transform: uppercase;
  transition: all 0.3s;
  width: 6rem;
  :hover {
    transform: translateY(-0.5rem);
  }
`;

const HighlightWord = ({ match, children }: { match?: string; children: string }) => (
  <span
    dangerouslySetInnerHTML={{
      __html: children.replace(match || "", `<b style="font-weight: 600">${match}</b>`),
    }}
  />
);
