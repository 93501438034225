import { IListJourneyV3ResItem } from "@dailyhuman/web-core/dist/api/game/res";
import { Footer } from "components/Footer";
import JourneyList from "components/Journey/JourneyList";
import { useGetUserJourney } from "hooks/useGetUserJourney";
import { useEffect, useState } from "react";

export const HomePage = () => {
  const [page, setPage] = useState(1);
  const [journeyConcat, setJourneyConcat] = useState<IListJourneyV3ResItem[]>([]);
  const { journey, isJourneyLoading } = useGetUserJourney({
    page,
  });

  const handleLoadMoreClick = () => {
    setPage(prev => prev + 1);
  };

  useEffect(() => {
    if (!journey?.data) return;
    setJourneyConcat([...journeyConcat, ...journey.data]);
    // Disable deps rule, we want to run this effect only when journey changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journey, setJourneyConcat]);

  const loadMore =
    !isJourneyLoading &&
    journey?.data &&
    page < Math.ceil(journey.pagination.total / 20)
      ? handleLoadMoreClick
      : undefined;

  return (
    <div>
      <JourneyList
        journey={journeyConcat}
        isLoading={isJourneyLoading}
        onLoadMore={loadMore}
      />
      <Footer />
    </div>
  );
};
