import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useContext } from "react";

import { SnackBarContext } from "../../components/base/Alert/Alert";
import { apiRoute, commonHeaders } from "../../utils/api";

type MutateDataType = {
  cycleId: string;
};

function useLabTermination() {
  const context = useContext(SnackBarContext);
  const {
    mutate: closeLab,
    isPending: isLabClosing, // Changed from isLoading to isPending
    isError,
  } = useMutation({
    mutationFn: (data: MutateDataType) =>
      axios.post<{ scheduledCompleteAt: string }>(
        `${apiRoute}/game/cycle/${data.cycleId}/terminate`,
        undefined,
        commonHeaders(),
      ),
    onError: (error: AxiosError) => {
      context?.setSnackBar({
        status: "failure",
        text: error.message,
      });
    },
  });

  return {
    closeLab,
    isLabClosing,
    isError,
  };
}

export { useLabTermination };
