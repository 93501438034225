import Accordion from "components/animations/Accordion";
import { useGetOnboardingGroupInfo } from "hooks/useGetOnboardingGroupInfo";
import { createContext, useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { CheckEmailStep } from "../steps/CheckEmailStep";
import ContactStep from "../steps/ContactStep";
import EmailStep from "../steps/EmailStep";
import InitialStep from "../steps/InitialStep";
import { JoinLabStep } from "../steps/JoinLabStep";
import { NameAndTimezoneStep } from "../steps/NameAndTimezone";
import { PaymentStep } from "../steps/PaymentStep";
import PhoneStep from "../steps/PhoneStep";
import { PronoumsStep } from "../steps/PronoumsStep";
import { UserExistsStep } from "../steps/UserExistsStep";
import { VerificationStep } from "../steps/VerificationStep";
import RegisterForm from "./RegisterForm";

type StepsState =
  | "InitialStep"
  | "InputEmailStep"
  | "UserExistsStep"
  | "CheckEmailStep"
  | "EmailVerificationStep"
  | "PhoneStep"
  | "PhoneVerificationStep"
  | "WhatsappVerificationStep"
  | "NameAndTimezoneStep"
  | "PronoumsStep"
  | "PaymentStep"
  | "JoinLabStep"
  | "ContactStep";

interface OnboardingContextType {
  mainStep: StepsState;
  setMainStep: React.Dispatch<React.SetStateAction<StepsState>>;
  registerState: RegisterState;
  setRegisterState: React.Dispatch<React.SetStateAction<RegisterState>>;
}

export const OnboardingContext = createContext<OnboardingContextType | undefined>(
  undefined,
);

export const useOnboardingContext = () => useContext(OnboardingContext);

export interface RegisterState {
  smsCode: string;
  emailCode: string;
  whatsappCode: string;
}

export const OnboardingUserFlow = () => {
  const { poolSlug } = useParams<{ poolSlug: string }>();
  const [searchParams] = useSearchParams();
  const { poolInfo } = useGetOnboardingGroupInfo(poolSlug);

  const [mainStep, setMainStep] = useState<StepsState>("InitialStep");
  const [showStep, setShowStep] = useState<StepsState>(mainStep);
  const [isStepChaning, setIsStepChaning] = useState(false);
  const [registerState, setRegisterState] = useState<RegisterState>({
    smsCode: "",
    emailCode: "",
    whatsappCode: "",
  });

  useEffect(() => {
    if (mainStep === "InitialStep" && searchParams.get("skipintro") && poolInfo?.data) {
      setMainStep(poolInfo?.data.isPaid ? "PaymentStep" : "JoinLabStep");
    }
  }, [searchParams, poolInfo, mainStep]);

  useEffect(() => {
    setIsStepChaning(true);
    const timeout = setTimeout(() => {
      setShowStep(mainStep);
      setIsStepChaning(false);
    }, 600);
    return () => clearTimeout(timeout);
  }, [mainStep]);

  const handlePhoneVerificationCodeComplete = (code: string, isWhatsapp: boolean) => {
    setRegisterState(prevState => {
      return {
        ...prevState,
        smsCode: isWhatsapp ? "" : code,
        whatsappCode: isWhatsapp ? code : "",
      };
    });
    setMainStep(poolInfo?.data.isPaid ? "InputEmailStep" : "NameAndTimezoneStep");
  };

  const handleEmailVerificationCodeComplete = (code: string) => {
    setRegisterState(prevState => {
      return {
        ...prevState,
        emailCode: code,
      };
    });
    setMainStep("NameAndTimezoneStep");
  };

  if (!poolInfo) return <></>;

  return (
    <OnboardingContext.Provider
      value={{ mainStep, setMainStep, registerState, setRegisterState }}
    >
      <RegisterForm>
        <FlowWrapper step={mainStep}>
          <Accordion>
            <StepSlider isStepChaning={isStepChaning}>
              {showStep === "InitialStep" && <InitialStep />}
              {showStep === "PhoneStep" && <PhoneStep />}
              {showStep === "PhoneVerificationStep" && (
                <VerificationStep
                  type={"SMS"}
                  onVerificationComplete={handlePhoneVerificationCodeComplete}
                />
              )}
              {showStep === "InputEmailStep" && <EmailStep />}
              {showStep === "UserExistsStep" && <UserExistsStep />}
              {showStep === "CheckEmailStep" && <CheckEmailStep />}
              {showStep === "EmailVerificationStep" && (
                <VerificationStep
                  type={"EMAIL"}
                  onVerificationComplete={handleEmailVerificationCodeComplete}
                />
              )}
              {showStep === "NameAndTimezoneStep" && <NameAndTimezoneStep />}
              {showStep === "PronoumsStep" && <PronoumsStep />}
              {showStep === "JoinLabStep" && <JoinLabStep />}
              {showStep === "ContactStep" && <ContactStep />}
              {showStep === "PaymentStep" && <PaymentStep />}
            </StepSlider>
          </Accordion>
        </FlowWrapper>
      </RegisterForm>
    </OnboardingContext.Provider>
  );
};

const StepSlider = styled.div<{ isStepChaning: boolean }>`
  height: 100%;
  opacity: ${({ isStepChaning }) => (isStepChaning ? 0 : 1)};
  transition: all 0.5s
    ${({ isStepChaning }) => (isStepChaning ? "ease-in" : "ease-out")};
`;

const FlowWrapper = styled.div<{ step: StepsState }>`
  background: ${({ step }) => (step === "InitialStep" ? "#664df3" : "#eee7df")};
  border-radius: 36px;
  color: #241f18;
  max-height: 800px;
  padding: 1.5rem;
  transition: all 0.4s ease-in 0.6s;
  width: 320px;
  & > form {
    height: 100%;
  }
`;

export default OnboardingUserFlow;
