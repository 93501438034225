import { IListGroupUserAnalyticsRes } from "@dailyhuman/web-core/dist/api/game/res";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TCapacity } from "types/analytics";
import { apiRoute, commonHeaders } from "utils/api";

import { TAnalyticsGroupUser } from "./useAnalyticsGroupUser";

type TAnalyticsGroupUsers = {
  capacity: TCapacity;
  users: TAnalyticsGroupUser[];
};

export default function useAnalyticsGroupUsers({
  groupId,
  promptSetId,
}: {
  groupId?: string;
  promptSetId?: string;
}) {
  const { data: response, isFetching: isAnalyticsGroupUsersLoading } = useQuery({
    queryKey: ["useAnalyticsGroupUsers", groupId, promptSetId],
    queryFn: () =>
      axios.get<IListGroupUserAnalyticsRes>(
        `${apiRoute?.slice(0, -3)}/v2/recognition/analytics/group/${groupId}/user`,
        {
          ...commonHeaders(),
          params: { promptSetId },
        },
      ),
    enabled: !!groupId, // Ensures the query only runs when groupId is not null or undefined
  });

  const { data: groupUsers } = response?.data || {};

  const analyticsGroupUsers: TAnalyticsGroupUsers = {
    capacity: [],
    users:
      groupUsers
        ?.map(({ user, capacities }) => ({
          user,
          analytics: {
            capacity: !!capacities.length
              ? capacities?.map(capacity => ({
                  label: capacity.label,
                  value: capacity.score,
                  range: capacity.scoreRange,
                }))
              : undefined,
          },
        }))
        .sort((a, b) => {
          return a.user.name.localeCompare(b.user.name);
        }) || [],
  };

  return { analyticsGroupUsers, isAnalyticsGroupUsersLoading };
}
