import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Loading from "../../components/base/Loading";
import { BrowserStorageItem, localStorageService } from "../../utils/StorageHelper";

interface DelayedRedirectProps {
  delay: number;
  to: string;
}

const DelayedRedirect = ({ delay, to }: DelayedRedirectProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    const storedRedirectTime = localStorageService.get(
      BrowserStorageItem.REDIRECT_TIME,
    );
    const redirectTime = storedRedirectTime
      ? new Date(storedRedirectTime)
      : new Date(new Date().getTime() + delay * 1000);

    const updateCountdown = () => {
      const now = new Date();
      const remainingTime = Math.max(
        0,
        Math.round((redirectTime.getTime() - now.getTime()) / 1000),
      );

      if (remainingTime <= 0) {
        navigate(to);
      }
    };

    const intervalId = setInterval(updateCountdown, 1000);
    localStorageService.set(
      BrowserStorageItem.REDIRECT_TIME,
      redirectTime.toISOString(),
    );

    return () => clearInterval(intervalId);
  }, [delay, navigate, to]);

  return (
    <Modal visible={true}>
      <Content>
        <p>Generating your mindset card...</p>
        <Loading />
      </Content>
    </Modal>
  );
};

const Modal = styled.div<{ visible?: boolean }>`
  background: #f8f7f0;
  height: 100dvh;
  left: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  padding: 2rem;
  pointer-events: ${({ visible }) => (visible ? "auto" : "none")};
  position: fixed;
  text-align: center;
  top: 0;
  width: 100dvw;
  z-index: 101;
`;
const Content = styled.div`
  -ms-transform: translate(-50%, -50%);
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export default DelayedRedirect;
