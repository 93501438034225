import {
  IGameChatHistory,
  IListGameChatHistoryRes,
} from "@dailyhuman/web-core/dist/api/game/res";
import { MessageDirection } from "@dailyhuman/web-core/dist/api/user";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { SnackBarContext } from "../components/base/Alert/Alert";
import { apiRoute, commonHeaders, queryClient } from "../utils/api";

type MutateDataType = {
  cycleId: string;
  recipientId: string;
  message: string;
  score?: string;
};

function usePostNewMessage() {
  const context = useContext(SnackBarContext);
  const navigate = useNavigate();
  const {
    mutate: addMessage,
    isPending: isAddingMessage, // Changed from isLoading to isPending
    isError,
  } = useMutation({
    mutationFn: (data: MutateDataType) =>
      axios.post<IGameChatHistory>(`${apiRoute}/recognition`, data, commonHeaders()),
    retry: (failureCount, error) => {
      return failureCount < 3 && !!error;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["getGameMessages"] });
    },
    onError: (error: AxiosError) => {
      context?.setSnackBar({
        status: "failure",
        text: error.response?.status === 404 ? "Lab was closed" : error.message,
      });
      if (error.response?.status === 404) {
        navigate("/");
      }
    },
    onMutate: async variables => {
      await queryClient.cancelQueries({ queryKey: ["getGameMessages"] });
      queryClient.setQueryData<{ data: IListGameChatHistoryRes }>(
        ["getGameMessages"],
        oldData => {
          if (!oldData)
            return {
              data: {
                data: [
                  {
                    id: "random_id",
                    cycleId: variables.cycleId,
                    userId: variables.recipientId,
                    direction: MessageDirection.IN,
                    message: variables.message,
                    createdAt: new Date().toISOString(),
                  },
                ],
              } as IListGameChatHistoryRes,
            };

          return {
            data: {
              pagination: oldData.data.pagination,
              data: [
                ...oldData.data.data,
                {
                  id: "random_id",
                  cycleId: variables.cycleId,
                  userId: variables.recipientId,
                  direction: MessageDirection.IN,
                  message: variables.message,
                  createdAt: new Date().toISOString(),
                },
              ],
            },
          };
        },
      );
    },
  });

  return {
    addMessage,
    isAddingMessage,
    isError,
  };
}

export { usePostNewMessage };
